import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import antd from 'ant-design-vue'
import router from './lib/router'
import store, {key} from '@/lib/store'
import '@/api/mqtt'
import './api/mock/mock'

createApp(App).use(store, key).use(router).use(antd).mount('#app')
